import React, { useState } from "react"

import { VideoModal } from "../common"

const VideoTeaser = ({ heading, video, link_text }) => {
  const [modalVideo, setModalVideo] = useState(null)
  return (
    <>
      <section className="mv4 mv5-l h-wrapper">
        <h2 className="f3 f1-l measure-narrow tc center lh-copy">
          {heading.text}
        </h2>
        <button
          className="center db ph5 pv3 br-pill bg-black white f4 button-reset::-moz-focus-inner pointer mt5"
          onClick={() => setModalVideo(video)}
        >
          {link_text ? link_text.text : "Play video"}
        </button>
      </section>
      <VideoModal video={modalVideo} onClose={() => setModalVideo(null)} />
    </>
  )
}

export default VideoTeaser
