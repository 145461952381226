import React, { useContext } from "react"

import { Link } from "gatsby"
import { AppContext } from "../../context/appContext"

import PrevArrow from "../../svg/arrow-left.svg"
import NextArrow from "../../svg/arrow-right.svg"

const FooterLink = ({ uid, name, direction, isFirst = false }) => {
  const { handlizeTitle } = useContext(AppContext)
  const path = !isFirst ? handlizeTitle(name) : "/"
  return (
    <Link
      to={`/${uid}/${path}`}
      className={`link black f4 flex items-center ${
        direction === "next" ? "ml-auto" : "flex-row-reverse"
      }`}
    >
      {name}
      {direction === "next" ? (
        <NextArrow className="w2 ml3" />
      ) : (
        <PrevArrow className="w2 mr3" />
      )}
    </Link>
  )
}

const PitchFooter = ({ prevLink, nextLink, uid }) => {
  return (
    <section className="mv4 mv5-l pv4 flex h-wrapper justify-between">
      {prevLink && <FooterLink {...prevLink} uid={uid} direction="prev" />}
      {nextLink && <FooterLink {...nextLink} uid={uid} direction="next" />}
    </section>
  )
}

export default PitchFooter
