import { useState, useContext } from "react"

import { AppContext } from "../../context/appContext"

function useFormValidation(initialState, users) {
  const [values, setValues] = useState(initialState)
  const [errors, setErrors] = useState(null)
  const { userLogin } = useContext(AppContext)
  //--
  function handleChange(event) {
    event.persist()
    setValues(previousValues => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }))
  }
  function handleBlur() {
    errors && setErrors(null)
  }
  //--
  function handleSubmit(event) {
    event.preventDefault()
    const { email } = values
    const validUser = users.filter(user => user.email.text === email)
    const isValidUser = validUser.length >= 1
    if (isValidUser) {
      setErrors("")
      userLogin(email)
    } else {
      setErrors("Sorry you do not have access to this pitch")
    }
  }

  return { handleChange, handleSubmit, handleBlur, values, errors }
}

export default useFormValidation
