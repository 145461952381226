import React from "react"

import { Link } from "gatsby"
// import Img from "gatsby-image"

const PitchDeckCard = ({ title, uid, portal_image }) => {
  return (
    <Link to={`/${uid}`} className="w-80 w-30-l link black shadow-5 mv3">
      {/* <Img fluid={portal_image.url} /> */}
      <img src={portal_image.url} alt={title.text} />
      <h4 className="f4 pa4 mb0">{title.text}</h4>
    </Link>
  )
}

export default PitchDeckCard
