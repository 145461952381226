import React, { useState } from "react"

import { PrismicImage, VideoModal } from "../common"

const CaseStudy = ({
  logo,
  image,
  description,
  reverse_text,
  background_color,
  tags,
  video,
  onClick,
}) => {
  const reverseText = reverse_text === "true"
  return (
    <div
      className="mv4 w-100 flex-l items center flex-wrap flex-row-reverse items-center"
      style={{
        backgroundColor: background_color,
      }}
    >
      <div className="w-100 w-50-l">
        <PrismicImage {...image} />
      </div>
      <div className="w-100 w-50-l h-wrapper pv5 pv0-l">
        <div className={`f5 measure center ${reverseText && "white"}`}>
          {logo && <PrismicImage {...logo} className="client-logo" />}
          <div
            className="f5 measure lh-copy"
            dangerouslySetInnerHTML={{ __html: description.html }}
          />
          {tags && (
            <div className="flex w-100 mt4">
              {tags.map(item => (
                <span className="f6 ph2 ph3-l f5-l pv2 bg-white ba br-pill b--light-gray mr2 black">
                  {item}
                </span>
              ))}
            </div>
          )}
          {video.url && (
            <button
              className={`center dib ph5 pv3 br-pill f4 button-reset::-moz-focus-inner pointer mt5 white ${
                reverseText ? "bg-transparent" : "bg-black"
              }`}
              onClick={onClick}
            >
              Play Video
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

const CaseStudies = ({ items }) => {
  const [modalVideo, setModalVideo] = useState(null)
  return (
    <>
      <section className="mv4 mv5-l">
        {items.map(item => {
          const { data, id, tags } = item.case_study.document
          return (
            <CaseStudy
              {...data}
              tags={tags}
              key={id}
              onClick={() => setModalVideo(data.video)}
            />
          )
        })}
      </section>
      <VideoModal video={modalVideo} onClose={() => setModalVideo(null)} />
    </>
  )
}

export default CaseStudies
