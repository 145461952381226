import React from "react"

const TextBlock = ({ heading, text }) => {
  return (
    <section className="mv4 mv5-l h-wrapper w-100">
      <div className="mw-text center">
        <h2 className="f2 f1-l measure mb4">{heading.text}</h2>
        <div
          dangerouslySetInnerHTML={{ __html: text.html }}
          className="f4 lh-copy"
        />
      </div>
    </section>
  )
}

export default TextBlock
