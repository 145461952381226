import React from "react"

const TextWithBg = ({ text, background_colour, text_color }) => {
  return (
    <div
      className="mv4 mv5-l h-wrapper w-100 pv6"
      style={{ background: background_colour, color: text_color }}
    >
      <div className="mw-text center">
        <div
          dangerouslySetInnerHTML={{ __html: text.html }}
          className="f4 lh-copy body-copy"
        />
      </div>
    </div>
  )
}

export default TextWithBg
