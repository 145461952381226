import React from "react"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

import styled from "styled-components"

export const PrismicImage = ({ localFile, url, ...props }) => {
  return (
    <>
      {localFile ? (
        <Img {...localFile.childImageSharp} {...props} />
      ) : (
        <img
          src={url}
          {...props}
          style={{ width: "100%", height: "auto" }}
          alt=""
        />
      )}
    </>
  )
}

const StyledBackground = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
`

export const PrismicBackgroundImage = ({ localFile, url, ...props }) => {
  return (
    <>
      {localFile ? (
        <BackgroundImage {...props} {...localFile.childImageSharp} />
      ) : (
        <StyledBackground src={url} {...props}></StyledBackground>
      )}
    </>
  )
}
