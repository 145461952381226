import React from "react"
import { useSpring, animated, config } from "react-spring"

const BraveHamburger = ({ menuState, reversed }) => {
  const style = {
    overflow: "visible",
    cursor: "pointer",
    position: "relative",
    fontSize: "2em",
    color: "black",
    // disable touch highlighting on devices
    WebkitTapHighlightColor: "rgba(255,255,255,255)",
  }
  const { x, color } = useSpring({
    x: menuState ? 1 : 0,
    color: "#000000",
    config: config.wobbly,
  })
  return (
    <svg viewBox="0 0 96 96" height="1em" style={style}>
      <g
        id="navicon"
        fill="none"
        stroke="currentColor"
        strokeWidth="10"
        strokeLinecap="square"
        strokeLinejoin="square"
      >
        <animated.line
          x1="7"
          y1="26"
          x2="89"
          y2="26"
          transform={x.interpolate(
            x => `translate(${x * 12}, ${x * -7}) rotate(${x * 45}, 7, 26)`
          )}
          style={{
            color: color,
          }}
        />
        <animated.line
          x1="7"
          y1="70"
          x2="89"
          y2="70"
          transform={x.interpolate(
            x => `translate(${x * 12}, ${x * 7}) rotate(${x * -45}, 7, 70)`
          )}
          style={{
            color: color,
          }}
        />
      </g>
    </svg>
  )
}

export default BraveHamburger
