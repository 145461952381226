import React from "react"

const EnquiryPrompt = ({ heading }) => {
  return (
    <section className="mv4 mv5-l h-wrapper tc">
      <h2 className="f2 f1-l measure-narrow tc center lh-copy">
        {heading.text}
      </h2>
      <a
        href="mailto: studio@bravemedia.nz"
        className="link center dib ph5 pv3 br-pill bg-black white f4 button-reset::-moz-focus-inner pointer mt5"
      >
        Contact us
      </a>
    </section>
  )
}

export default EnquiryPrompt
