import React from "react"

const PricingTable = ({ terms, intro, items }) => {
  return (
    <section className="mv4 mv5-l h-wrapper">
      <div className="mw-text center">
        <div
          className="f4 lh-copy mb5"
          dangerouslySetInnerHTML={{ __html: intro.html }}
        />
        {items.map(item => {
          const { line_title, line_price, description, sale_price } = item
          return (
            <div className="mv4">
              <div className="flex-ns bb items-center justify-between pv3">
                <h3 className="f4 lh-copy b">{line_title.text}</h3>
                <div className="flex">
                  <h3
                    className={`f4 mt3 mt0-ns ${sale_price.text && "strike"}`}
                  >
                    {line_price.text}
                  </h3>
                  {sale_price.text && (
                    <h3 className="f4 mt3 mt0-ns ml4">{sale_price.text}</h3>
                  )}
                </div>
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: description.html }}
                className="f4 lh-copy pricing-table-description"
              />
            </div>
          )
        })}
        <div
          dangerouslySetInnerHTML={{ __html: terms.html }}
          className="f6 lh-copy mt5"
        />
      </div>
    </section>
  )
}

export default PricingTable
