import React from "react"
import { useInView } from "react-intersection-observer"
import CountUp from "react-countup"

const StatBlock = ({ items }) => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
  })
  return (
    <section className="mv4 mv5-l h-wrapper w-100" ref={ref}>
      <div className="mw-text center">
        {items.map(item => (
          <div className="w-100 flex flex-wrap items center mv3 mv4-l">
            <div class="f1 f-subheadline-l flex items-center align-center justify-start w-100 w-30-ns">
              <span>{item.prefix}</span>
              <CountUp start={0} end={inView ? item.number : 0}>
                {({ countUpRef }) => <span ref={countUpRef} />}
              </CountUp>
              <span>{item.suffix}</span>
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: item.text.html }}
              className="w-100 w-60-ns pl4-ns  f4 lh-copy"
            />
          </div>
        ))}
      </div>
    </section>
  )
}

export default StatBlock
