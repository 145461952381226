import React from "react"

import { PrismicImage } from "../common"

const ImageBlock = ({ image, horizontal_wrap }) => {
  return (
    <section
      className={`mv4 mv5-l ${horizontal_wrap === "true" && "h-wrapper"}`}
    >
      <PrismicImage {...image} />
    </section>
  )
}

export default ImageBlock
