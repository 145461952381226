import React from "react"

const Headline = ({ text, size }) => {
  const fontSize = size === "large" ? "f3 f2-l" : "f3"
  return (
    <section className="h-wrapper mv4 mv5-l">
      <div className="mw-text center">
        <div
          dangerouslySetInnerHTML={{ __html: text.html }}
          className={`lh-copy measure center ${fontSize}`}
        />
      </div>
    </section>
  )
}

export default Headline
