import React from "react"

const KeyRequirements = ({ heading, items }) => {
  return (
    <section className="mv4 mv5-l h-wrapper w-100">
      <div className="mw-text center">
        <h2 className="f2 f1-l measure mb4">{heading.text}</h2>
        <div className="flex flex-wrap justify-between items-start">
          {items.map(item => (
            <span className="w-100 w-50-ns mv3 f4 pr4-ns lh-copy">
              {item.requirement.text}
            </span>
          ))}
        </div>
      </div>
    </section>
  )
}

export default KeyRequirements
