import React from "react"
import ImagesLoaded from "react-images-loaded"
import ScrollBooster from "scrollbooster"

class BeltDragger extends React.Component {
  handleDone = instance => {
    if (this.content && this.viewport) {
      const viewport = this.viewport
      const content = this.content
      new ScrollBooster({
        viewport, // this parameter is required
        content, // scrollable element
        mode: "x", // scroll only in horizontal dimension
        onUpdate: data => {
          // your scroll logic goes here
          content.style.transform = `translateX(${-data.position.x}px)`
        },
      })
    }
  }

  render() {
    const { children } = this.props
    return (
      <ImagesLoaded done={this.handleDone}>
        <div
          className="belt-dragger-wrap w-100 overflow-hidden relative"
          ref={v => (this.viewport = v)}
        >
          <div className="belt-dragger-canvas" ref={c => (this.content = c)}>
            {children}
          </div>
        </div>
      </ImagesLoaded>
    )
  }
}

export default BeltDragger
