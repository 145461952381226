import React from "react"

import useFormValidation from "./useFormValidation"
import Logo from "../../svg/logo.svg"

const INITIAL_STATE = {}

const LoginForm = ({ users }) => {
  const { handleSubmit, handleChange, handleBlur, errors } = useFormValidation(
    INITIAL_STATE,
    users
  )
  return (
    <div className="w-100 vh-100 flex items-center justify-center ph4">
      <form className="tl w-100 mw6 center" onSubmit={handleSubmit}>
        <div>
          <Logo className="b-logo" />
        </div>
        <h1 className="f4 mv3">Sign in with email</h1>
        <div className="mt4 mb4 items-center">
          <input
            type="email"
            name="email"
            placeholder="Enter your email"
            className="f4 pa3 input-reset::-moz-focus-inner br2 ba b--moon-gray w-100"
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
          {errors && <span className="f6 mt2 db">{errors}</span>}
        </div>

        <button
          type="submit"
          className="db ph5 pv3 br-pill bg-black white f4 button-reset::-moz-focus-inner pointer"
        >
          Sign in
        </button>
      </form>
    </div>
  )
}

export default LoginForm
