import React, { useState } from "react"
import ReactPlayer from "react-player"
import { useTransition, animated } from "react-spring"

export const VideoModal = ({ video, onClose }) => {
  const [playing, setPlaying] = useState(false)

  const transitions = useTransition(video, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <animated.div
          key={key}
          style={props}
          className="w-100 h-100 fixed top-0 left-0 bg-black-90 flex items-center justify-center z-max"
        >
          <button
            onClick={() => onClose()}
            className="bg-transparent absolute top-1 right-1 white bn link"
          >
            Close
          </button>
          {video && (
            <ReactPlayer
              url={video.url}
              playing={playing}
              playsinline={false}
              width="80%"
              height="auto"
              onReady={() => setPlaying(true)}
              onEnded={() => onClose()}
              controls={true}
            />
          )}
        </animated.div>
      )
  )
}
