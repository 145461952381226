import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import PitchDeckCard from "./pitchDeckCard"

const PitchDeckList = () => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicDigitalPitchDeck(filter: { tags: { nin: "CONTENT-MODEL" } }) {
        nodes {
          uid
          data {
            portal_image {
              url
            }
            title {
              text
            }
          }
        }
      }
    }
  `)
  const { nodes } = data.allPrismicDigitalPitchDeck
  return (
    <div className="flex-l justify-between h-wrapper mv4 mv6-l flex-wrap">
      {nodes.map(item => (
        <PitchDeckCard {...item.data} uid={item.uid} />
      ))}
    </div>
  )
}

export default PitchDeckList
