import React from "react"

const PageIntro = ({ heading, subheading }) => {
  return (
    <section className="mv4 mv5-l h-wrapper">
      <h4 className="f4">{subheading.text}</h4>
      <h1 className="f2 f1-l measure-heading lh-copy">{heading.text}</h1>
    </section>
  )
}

export default PageIntro
