import React, { useContext } from "react"

import LoginForm from "../forms/loginForm"

import { AppContext } from "../../context/appContext"

const EmailMask = ({ children, users }) => {
  const { isLoggedIn } = useContext(AppContext)
  return <>{isLoggedIn ? children : <LoginForm users={users} />}</>
}

export default EmailMask
