import React from "react"

import { PrismicImage } from "../common"

const TwoUpImage = ({ small_image, large_image }) => {
  return (
    <section className="mv4 mv5-l h-wrapper flex items-center justify-center">
      <div className="w-40 w-30-l mr3 mr5-l">
        <PrismicImage {...small_image} />
      </div>
      <div className="w-50 w-40-l ml3 ml5-l">
        <PrismicImage {...large_image} />
      </div>
    </section>
  )
}

export default TwoUpImage
