import React, { useRef, useState } from "react"
import { useSpring, animated, useTrail, useChain, config } from "react-spring"
import { Waypoint } from "react-waypoint"

const ServicesGrid = ({ heading, items }) => {
  const [isVisible, setVisible] = useState(false)

  const springRef = useRef()
  const { opacity } = useSpring({
    ref: springRef,
    from: {
      opacity: "0",
    },
    to: {
      opacity: isVisible ? "1" : "0",
    },
  })
  // Trail
  const trailRef = useRef()
  const trail = useTrail(items.length, {
    ref: trailRef,
    config: config.stiff,
    opacity: isVisible ? 1 : 0,
    x: isVisible ? 0 : 20,
    from: { opacity: 0, x: 20 },
  })
  useChain(isVisible ? [springRef, trailRef] : [trailRef, springRef])
  return (
    <section className="w-100 bg-black white h-wrapper pv6 mv4 mv6-l">
      <div className="mw8 center">
        <Waypoint
          onEnter={() => {
            if (!isVisible) setVisible(true)
          }}
          bottomOffset="30%"
        />
        <animated.div
          className="f1 pl4"
          style={{
            opacity: opacity,
          }}
        >
          {heading.text}
        </animated.div>
        <div className="flex justify-between flex-wrap mt4 mt5-l">
          {trail.map(({ x, ...rest }, index) => (
            <animated.div
              className="w-100 w-50-l ph4 mv2 mv4-l"
              key={items[index]}
              style={{
                ...rest,
                transform: x.interpolate(x => `translate3d(0,${x}px,0)`),
              }}
            >
              <div className="f3"> {items[index].service.text}</div>
              <div
                dangerouslySetInnerHTML={{
                  __html: items[index].description.html,
                }}
                className="f5 lh-copy"
              ></div>
            </animated.div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ServicesGrid
