import React, { useState } from "react"

import ReactPlayer from "react-player"

import { Waypoint } from "react-waypoint"

const UiVideo = ({ video, prototype_link }) => {
  const [isPlaying, setPlaying] = useState(false)
  return (
    <section className="mv4 mv5-l h-wrapper ui-video">
      <Waypoint
        onEnter={() => {
          if (!isPlaying) setPlaying(true)
        }}
        bottomOffset="0%"
      />
      <ReactPlayer
        url={video.url}
        playing={true}
        playsinline={true}
        width="100%"
        height="auto"
        controls={true}
        loop={true}
        muted={true}
        className="shadow-5 v-btm mw9 center"
      />
      {prototype_link.url && (
        <a
          href={prototype_link.url}
          target="_blank"
          rel="noopener noreferrer"
          className="link f3 f2-l center black w-100 tc db mt5 mt6-l underline"
        >
          Launch Prototype
        </a>
      )}
    </section>
  )
}

export default UiVideo
