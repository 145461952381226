import React from "react"
import { useSpring, animated } from "react-spring"

const PageTransition = ({ children }) => {
  const { x } = useSpring({
    from: {
      x: 0,
    },
    to: {
      x: 1,
    },
  })
  return (
    <animated.div
      style={{
        opacity: x.interpolate(x => x),
      }}
    >
      {children}
    </animated.div>
  )
}

export default PageTransition
