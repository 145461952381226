import React from "react"

import { PrismicImage } from "../common"

const ImageText = ({ image, text, reverse_layout }) => {
  const isReversed = reverse_layout === "true"
  return (
    <div
      className={`h-wrapper mv4 mv5-l flex-l items-center ${isReversed &&
        "flex-row-reverse-l"}`}
    >
      <div className="w-100 w-50-l">
        <PrismicImage {...image} />
      </div>
      <div className={`w-100 w-50-l ${isReversed ? "pr6-l" : "pl6-l"}`}>
        <div
          dangerouslySetInnerHTML={{ __html: text.html }}
          className="f4 lh-copy"
        />
      </div>
    </div>
  )
}

export default ImageText
