import React from "react"

import styled from "styled-components"

const StyledSpinner = styled.div`
  margin: 80px auto;
  width: 80px;
  height: 80px;
  position: relative;
  text-align: center;
  animation: sk-rotate 2s infinite linear;
  > div {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: #000;
    border-radius: 100%;
    animation: sk-bounce 2s infinite ease-in-out;
  }

  > div:nth-child(2) {
    top: auto;
    bottom: 0;
    animation-delay: -1s;
  }

  @keyframes sk-rotate {
    100% {
      transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
`

export const Spinner = ({ color }) => {
  return (
    <StyledSpinner>
      <div />
      <div />
    </StyledSpinner>
  )
}
