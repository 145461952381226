import React from "react"
import PropTypes from "prop-types"

import {
  PageIntro,
  TwoUpImage,
  Headline,
  TextBlock,
  KeyRequirements,
  VideoTeaser,
  ImageCarousel,
  TechStack,
  ServicesGrid,
  StaffBios,
  CaseStudies,
  ImageBlock,
  UiVideo,
  PricingTable,
  EnquiryPrompt,
  PageSpacer,
  TextWithBg,
  ImageText,
  StatBlock,
} from "./slices"

const SliceZone = ({ slices }) => {
  const slice = slices.map(s => {
    switch (s.slice_type) {
      case "page_intro":
        return <PageIntro key={s.id} {...s.primary} />
      case "two_up_image":
        return <TwoUpImage key={s.id} {...s.primary} />
      case "headline":
        return <Headline key={s.id} {...s.primary} />
      case "text_block":
        return <TextBlock key={s.id} {...s.primary} />
      case "key_requirements":
        return <KeyRequirements key={s.id} {...s.primary} items={s.items} />
      case "video_teaser":
        return <VideoTeaser key={s.id} {...s.primary} />
      case "image_carousel":
        return <ImageCarousel key={s.id} items={s.items} />
      case "tech_stack":
        return <TechStack key={s.id} {...s.primary} items={s.items} />
      case "services":
        return <ServicesGrid key={s.id} {...s.primary} items={s.items} />
      case "staff_bios":
        return <StaffBios key={s.id} {...s.primary} items={s.items} />
      case "case_studies":
        return <CaseStudies key={s.id} items={s.items} />
      case "image":
        return <ImageBlock key={s.id} {...s.primary} />
      case "ui_video":
        return <UiVideo key={s.id} {...s.primary} />
      case "enquiry_prompt":
        return <EnquiryPrompt key={s.id} {...s.primary} />
      case "pricing_table":
        return <PricingTable key={s.id} {...s.primary} items={s.items} />
      case "spacer":
        return <PageSpacer key={s.id} {...s.primary} />
      case "text_with_background":
        return <TextWithBg key={s.id} {...s.primary} />
      case "image___text":
        return <ImageText key={s.id} {...s.primary} />
      case "statistics":
        return <StatBlock key={s.id} {...s.primary} items={s.items}/>
      default:
        return null
    }
  })
  return (
    <div className="slice-zone flex flex-column bg-white mt4 mt6-l">
      {slice}
    </div>
  )
}

export default SliceZone

SliceZone.propTypes = {
  slices: PropTypes.array.isRequired,
}
