import React from "react"

import ReactPlayer from "react-player"

import { PrismicImage } from "../common"

import BeltDragger from "../sliders/beltDragger"

const CarouselImage = ({ image }) => {
  return (
    <div className="c-item">
      <PrismicImage {...image} />
    </div>
  )
}

const CarouselVideo = ({ video }) => {
  return (
    <div className="c-item">
      <ReactPlayer
        url={video.url}
        playing
        muted={true}
        playsinline={true}
        width="100%"
        loop={true}
      />
    </div>
  )
}

const ImageCarousel = ({ items }) => {
  return (
    <section className="mv4 mv6-l overflow-hidden w-100">
      <BeltDragger>
        <div className="inline-flex w-auto items-center">
          {items.map(item => {
            const { is_video } = item
            if (is_video === "true") {
              return <CarouselVideo {...item} />
            } else {
              return <CarouselImage {...item} />
            }
          })}
        </div>
      </BeltDragger>
    </section>
  )
}

export default ImageCarousel
