import React, { useState, useRef } from "react"
import styled from "styled-components"
import { match } from "styled-is"
import { PrismicImage } from "../common"

import { useSpring, animated, useTrail, useChain, config } from "react-spring"
import { Waypoint } from "react-waypoint"

const StyledTechStack = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  @media screen and (min-width: 64em) {
    grid-gap: 8rem;
    ${match("gridSize", "Three")`
      grid-template-columns: 1fr 1fr 1fr;
      `}
  }
`

const StyledStackItem = styled(props => <animated.div {...props} />)`
  text-align: center;
  > div:first-child {
    max-width: 120px;
  }
  > div:last-child {
    max-width: 44rem;
  }
  @media screen and (min-width: 64em) {
    > div:first-child {
      max-width: 180px;
    }
    > div:last-child {
      max-width: 34rem;
    }
  }
`

const TechStack = ({ heading, text, grid_size = "Three", items }) => {
  const [isVisible, setVisible] = useState(false)
  const springRef = useRef()
  const { x, y } = useSpring({
    ref: springRef,
    from: {
      x: 1,
      y: 0,
    },
    to: {
      x: isVisible ? 1 : 0,
      y: isVisible ? 0 : 20,
    },
    config: config.stiff,
  })
  const trailRef = useRef()
  const trail = useTrail(items.length, {
    ref: trailRef,
    config: config.slow,
    opacity: isVisible ? 1 : 0,
    x: isVisible ? 0 : 20,
    from: { opacity: 0, x: 20 },
  })
  useChain(isVisible ? [springRef, trailRef] : [trailRef, springRef])
  return (
    <section className="mv4 mv6-l h-wrapper-l">
      <Waypoint
        onEnter={() => {
          if (!isVisible) setVisible(true)
        }}
        bottomOffset="30%"
      />
      <animated.div
        className="tc ph4"
        style={{
          opacity: x.interpolate(x => x),
          transform: y.interpolate(y => `translate3d(0,${y}%, 0)`),
        }}
      >
        <h3 className="f2">{heading.text}</h3>
        <div
          className="f4 lh-copy measure center"
          dangerouslySetInnerHTML={{ __html: text.html }}
        />
      </animated.div>
      <StyledTechStack className="mt4 mt6-l ph2" gridSize={grid_size}>
        {trail.map(({ x, ...rest }, index) => (
          <StyledStackItem
            style={{
              ...rest,
              transform: x.interpolate(x => `translate3d(0,${x}%,0)`),
            }}
          >
            <div className="center mb4">
              <PrismicImage {...items[index].logo} />
            </div>
            <h5 className="f4 font-reg dn db-ns">{items[index].name.text}</h5>
            <div className="f5 lh-copy center mt2 dn db-ns">
              {items[index].description.text}
            </div>
          </StyledStackItem>
        ))}
      </StyledTechStack>
    </section>
  )
}

export default TechStack
