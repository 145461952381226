import React, { useState, useContext } from "react"
import { useTransition, animated, config } from "react-spring"
import Headroom from "react-headroom"
import { Link } from "gatsby"

import { AppContext } from "../../context/appContext"

import BraveHamburger from "../nav/braveHamburger"
import Logo from "../../svg/logo.svg"

const MobileNav = ({ uid, styles, navItems }) => {
  return (
    <animated.div
      className="w-100 h-100 bg-white fixed top-0 left-0 ph4 flex items-center justify-center z-1"
      style={{ ...styles }}
    >
      <div className="w-100">
        {navItems.map(item => {
          const showTab = item.show === "true"
          if (showTab) {
            return (
              <Link
                to={`/${uid}/${item.path}`}
                className="link black f3 db mv4"
                activeClassName="b"
              >
                {item.name}
              </Link>
            )
          } else {
            return null
          }
        })}
      </div>
    </animated.div>
  )
}

const DeckNav = ({
  uid,
  show_tab_one,
  show_tab_two,
  show_tab_three,
  show_tab_four,
  title_tab_one,
  title_tab_two,
  title_tab_three,
  title_tab_four,
}) => {
  const [isOpen, setOpen] = useState(false)
  const { handlizeTitle } = useContext(AppContext)
  const transitions = useTransition(isOpen, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.default,
  })
  const navItems = [
    {
      path: handlizeTitle(title_tab_one.text),
      name: title_tab_one.text,
      show: show_tab_one,
    },
    {
      path: handlizeTitle(title_tab_two.text),
      name: title_tab_two.text,
      show: show_tab_two,
    },
    {
      path: handlizeTitle(title_tab_three.text),
      name: title_tab_three.text,
      show: show_tab_three,
    },
    {
      path: handlizeTitle(title_tab_four.text),
      name: title_tab_four.text,
      show: show_tab_four,
    },
  ]
  return (
    <>
      <Headroom>
        <div className="flex justify-between items-center bg-white h-wrapper pv4 z-max">
          <Link to={`/${uid}`} className="b-logo">
            <Logo className="fill-black" />
          </Link>
          <div className="db dn-l" onClick={() => setOpen(!isOpen)}>
            <BraveHamburger menuState={isOpen} />
          </div>
          <div className="dn flex-l">
            {navItems.map(item => {
              const showTab = item.show === "true"
              if (showTab) {
                return (
                  <Link
                    to={`/${uid}/${item.path}`}
                    className="link black f5 ml4 fw3"
                    activeClassName="b"
                  >
                    {item.name}
                  </Link>
                )
              } else {
                return null
              }
            })}
          </div>
        </div>
      </Headroom>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <MobileNav styles={props} key={key} uid={uid} navItems={navItems} />
          )
      )}
    </>
  )
}

export default DeckNav
