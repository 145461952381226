import React from "react"

import { PrismicImage } from "../common"
import BeltDragger from "../sliders/beltDragger"

const StaffBio = ({ image, name, position, bio }) => {
  return (
    <div className="vw-60 vw-30-l mr4 mr0-l ml5-l">
      <PrismicImage {...image} />
      <h4 className="f3 mt3 mb2 font-reg">{name.text}</h4>
      <h5 className="f5">{position.text}</h5>
      <div
        dangerouslySetInnerHTML={{ __html: bio.html }}
        className="lh-copy measure"
      />
    </div>
  )
}

const StaffBios = ({ heading, items }) => {
  return (
    <section className="mv4 mv5-l">
      <BeltDragger>
        <div className="inline-flex w-auto b-carousel">
          <div className="vw-50 vw-20-l flex items-center justify-center">
            <div
              className="f3-l lh-copy v-type vw-100"
              dangerouslySetInnerHTML={{ __html: heading.html }}
            />
          </div>
          {items.map(
            item =>
              item.bio.document && <StaffBio {...item.bio.document.data} />
          )}
        </div>
      </BeltDragger>
    </section>
  )
}

export default StaffBios
